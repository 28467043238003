import React, { Component } from 'react';
import './Splash.css';

class Splash extends Component {

    render() {
        return (
            <div className="splashScreen">
                <span>Select a channel to start watching TV.</span>
            </div>
        );
    }

}

export default Splash;